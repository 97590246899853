<template>
  <v-card class="pa-4" flat>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
      align="center"
    >
      <div v-if="$vuetify.breakpoint.lgAndUp" class="d-flex flex-row">
        <v-select
          v-model="idSchoolYear"
          :items="dataSchoolYear"
          :label="$t('subjects.school_year')"
          :loading="loadingSchoolYear"
          :item-text="item => schoolYearName(item)"
          item-value="id"
          outlined
          hide-details
          dense
          class="mr-2 select-225 caption"
          @change="initData()"
        >
          <template #item="{ item }">
            <span class="caption">
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
        </v-select>
        <v-select
          v-model="queryData.filter.class"
          :items="dataGrade"
          :label="$t('basic_competencies.choose_grade')"
          :loading="loadingClass"
          :disabled="loadingSchoolYear"
          item-text="grade"
          item-value="id"
          outlined
          hide-details
          dense
          multiple
          class="mr-2 select-200"
          @change="initData()"
        >
          <template slot="prepend-item">
            <v-list-item @click="handleClickAll()">
              <v-list-item-action>
                <v-checkbox :value="selectAllGrade"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :class="selectAllGrade && 'primary--text'">
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ queryData.filter.class.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
      </div>
      <div v-else>
        <v-btn color="primary" @click="bottomMenu = !bottomMenu" depressed>
          <v-icon>mdi-filter-outline</v-icon>
        </v-btn>
      </div>
      <v-btn
        :loading="loadingDownloadExcel"
        :disabled="dataTable.length === 0"
        class="gradient-primary ml-2 mr-4 caption"
        dark
        depressed
      >
        <download-excel
          :fetch="downloadExcel"
          :fields="fields"
          :name="fileName()"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
            $t("app.download")
          }}</span>
          <v-icon>mdi-cloud-download</v-icon>
        </download-excel>
      </v-btn>
      <div class="font-weight-bold subtitle-2">
        {{ total }} {{ $t("routes.basic_competencies") }}
      </div>

      <v-spacer />

      <v-text-field
        v-model="queryData.search"
        :label="$t('app.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        hide-details
        class="mr-3"
        style="max-width: 250px"
        clearable
        @keydown="searchHandler()"
        @click:clear="searchHandler()"
      />
    </v-row>

    <v-divider class="my-4"></v-divider>

    <div v-if="selected.length > 0" class="d-flex justify-end mb-3">
      <v-btn small color="error" @click="toggleModalDelete(true)">
        {{ $t("basic_competencies.remove_selected_competencies") }}
        <v-icon dark class="ml-1">mdi-delete-forever</v-icon>
      </v-btn>
    </div>

    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="dataTable"
      :page.sync="queryData.page"
      :items-per-page="queryData.limit"
      :loading="loading"
      :options.sync="options"
      disable-pagination
      fixed-header
      show-select
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.lesson="{ item }">
        {{ item.lesson }} {{ item.sub_lesson }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          icon
          small
          dark
          class="primary mx-2"
          :disabled="loading"
          @click="getCompetenciesById(item.id, 'edit')"
        >
          <v-icon small>mdi-pencil-box-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          :dark="selected.length > 0 ? false : true"
          :class="
            selected.length > 1 || item.total_kd == 0 ? 'grey' : 'primary'
          "
          :disabled="loading || selected.length > 0 || item.total_kd == 0"
          @click="toggleModalDelete(true, item)"
        >
          <v-icon small>mdi-delete-forever</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-bottom-sheet v-model="bottomMenu">
      <v-sheet class="text-center pa-6">
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <v-select
              v-model="idSchoolYear"
              :items="dataSchoolYear"
              :label="$t('subjects.school_year')"
              :loading="loadingSchoolYear"
              :item-text="item => schoolYearName(item)"
              item-value="id"
              outlined
              hide-details
              dense
              @change="initData()"
            >
              <template #item="{ item }">
                <span class="caption">
                  {{ item.start_year }} / {{ item.end_year }}, semester
                  {{ item.semester }}
                </span>
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      :color="schoolYearInfo(item.status).color"
                      small
                      class="ml-1"
                      >{{ schoolYearInfo(item.status).name }}</v-icon
                    >
                  </template>
                  <span>{{ schoolYearInfo(item.status).info }}</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="queryData.filter.class"
              :items="dataGrade"
              :label="$t('basic_competencies.choose_grade')"
              :loading="loadingClass"
              :disabled="loadingSchoolYear"
              item-text="grade"
              item-value="id"
              outlined
              hide-details
              dense
              multiple
              @change="initData()"
            >
              <template slot="prepend-item">
                <v-list-item @click="handleClickAll()">
                  <v-list-item-action>
                    <v-checkbox :value="selectAllGrade"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content
                    :class="selectAllGrade && 'primary--text'"
                  >
                    {{ $t("app.select_all") }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
                <span v-if="index === 1" class="grey--text">
                  (+{{ queryData.filter.class.length - 1 }}
                  {{ $t("app.others") }})
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

    <Pagination
      :length="length"
      :total="total"
      :current-page="queryData.page"
      :limit="queryData.limit"
      :handler="paginateChange"
    />

    <BasicCompetenciesForm
      :modalMasterStatus="modalDialogMaster"
      :toggleDialogMaster="toggleDialogMaster"
      :formType="formType"
      :title="modalTitle"
      :changeFormType="
        () => {
          this.formType = 'edit';
          this.modalTitle = $t('basic_competencies.edit_basic_competencies');
        }
      "
      :competencies="tempData"
      @getValue="initData()"
    />

    <ModalConfirm
      :isOpen="confirmDelete"
      :close="toggleModalDelete"
      :save="deleteCompetenciesById"
      :content="$t('basic_competencies.confirm_delete')"
      :loading="loading"
    />
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import {
  basic_competencies_list,
  delete_competencies
} from "@/api/admin/academic/basicCompetencies";
import { get_school_year_list, get_grade_list } from "@/api/admin/schoolClass";

let typingTimer;
const doneTypingInterval = 750;

export default {
  metaInfo: {
    title: i18n.t("routes.basic_competencies"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    BasicCompetenciesForm: () => import("./BasicCompetenciesForm"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.getSchoolYear();
    this.getGrade();
  },
  data() {
    return {
      bottomMenu: false,
      selectAllGrade: false,
      selected: [],
      loading: false,
      modalDialogMaster: false,
      confirmDelete: false,
      delete_id: null,
      selectedId: null,
      formType: "add",
      modalTitle: "",
      tempData: {},
      options: {},
      fields: {
        [i18n.t("core_competencies.table.subject")]: {
          field: "id",
          callback: value => {
            const getSubLesson = this.dataTableTemp.find(r => r.id == value);
            if (getSubLesson) {
              const lesson = `${getSubLesson.lesson} ${
                getSubLesson.sub_lesson ? getSubLesson.sub_lesson : ""
              }`;
              return lesson;
            } else return getSubLesson.lesson ? getSubLesson.lesson : "";
          }
        },
        [i18n.t("basic_competencies.table.year")]: "year_active",
        [i18n.t("basic_competencies.table.grade")]: "grade",
        [i18n.t("basic_competencies.table.total_competencies")]: "total_kd"
      },
      tableHeaders: [
        {
          text: i18n.t("basic_competencies.table.subject"),
          align: "left",
          value: "lesson"
        },
        {
          text: i18n.t("basic_competencies.table.year"),
          value: "year_active"
        },
        {
          text: i18n.t("basic_competencies.table.grade"),
          value: "grade"
        },
        {
          text: i18n.t("basic_competencies.table.total_competencies"),
          sortable: false,
          value: "total_kd"
        },
        {
          text: i18n.t("basic_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      idSchoolYear: 0,
      queryData: {
        page: 1,
        limit: 10,
        sort: "lesson",
        order: "ASC",
        search: "",
        filter: { school_year: 0, class: [] }
      },
      total: 0,
      length: 0,
      dataTable: [],
      dataTableTemp: [],
      dataGrade: [],
      dataSchoolYear: [],
      loadingSchoolYear: false,
      loadingClass: false,
      loadingDownloadExcel: false,
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "homeroom_teacher" : ""
    };
  },
  watch: {
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.queryData.sort = param.sortBy.length > 0 ? param.sortBy[0] : "";
          this.initData();
        }
      }
    }
  },
  methods: {
    fileName() {
      const filter = this.queryData.filter;
      if (this.dataSchoolYear.length) {
        const schoolYear = this.schoolYearName(
          this.dataSchoolYear.find(item => item.id === filter.school_year[0]),
          "-"
        );
        let grade = "";
        if (filter.class.length) {
          grade =
            " " +
            this.$i18n.t("app.grade").concat(
              " " +
                this.dataGrade
                  .filter(item =>
                    filter.class.some(idClass => idClass === item.id)
                  )
                  .map(item => item.grade)
                  .join(", ")
            );
        }
        return `${this.$i18n.t("routes.basic_competencies")} ${this.$i18n.t(
          "app.school_year"
        )} ${schoolYear}${grade}.xls`;
      }
    },
    schoolYearName: (item, separator) =>
      `${item.start_year} ${separator || "/"} ${item.end_year}, Semester ${
        item.semester
      }`,
    handleClickAll() {
      this.selectAllGrade = !this.selectAllGrade;
      if (this.selectAllGrade) {
        this.queryData.filter.class = this.dataGrade.map(grade => grade.id);
      } else {
        this.queryData.filter.class = [];
      }
      this.initData();
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    getGrade() {
      get_grade_list({ type: this.typeList }).then(res => {
        this.dataGrade = res.data.data;
      });
    },
    getSchoolYear() {
      this.loadingSchoolYear = true;
      get_school_year_list(false).then(res => {
        let r = res.data.data;
        let itemSchoolYear = r.find(d => d.status == 1);
        this.dataSchoolYear = r;
        this.idSchoolYear = itemSchoolYear.id;
        this.queryData.filter.school_year = [itemSchoolYear.id];
        this.loadingSchoolYear = false;
        this.initData();
      });
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.initData();
      }, doneTypingInterval);
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;
      this.dataTableTemp = [];

      const res = await basic_competencies_list(query);
      this.loadingDownloadExcel = false;

      if (res.data.code) {
        this.dataTableTemp = res.data.data.data;
        if (res.data.data.data.length == 0) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("app.data_not_found"),
            color: "error"
          });
        } else return res.data.data.data;
      }
    },
    toggleDialogMaster(type) {
      this.formType = type;
      if (type == "add") {
        this.modalTitle = "";
      }
      this.modalDialogMaster = !this.modalDialogMaster;
    },
    initData() {
      this.loading = true;
      // handle value select all checkbox
      if (this.queryData.filter.class.length === this.dataGrade.length) {
        this.selectAllGrade = true;
      } else {
        this.selectAllGrade = false;
      }
      this.queryData.filter.school_year = [this.idSchoolYear];
      basic_competencies_list(this.queryData)
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data.data;
            this.total = res.data.data.total;
            this.length = res.data.data.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.initData();
    },
    getCompetenciesById(id, type) {
      this.selectedId = id;
      this.loading = true;
      setTimeout(() => {
        const temp = this.dataTable.filter(d => d.id == id)[0];
        this.tempData = { ...temp };

        if (type == "edit") {
          this.modalTitle = i18n.t(
            "basic_competencies.edit_basic_competencies"
          );
          this.toggleDialogMaster("edit");
        } else {
          this.modalTitle = i18n.t(
            "basic_competencies.detail_basic_competencies"
          );
          this.toggleDialogMaster("detail");
        }
        this.loading = false;
      }, 1000);
    },
    toggleModalDelete(isOpen, item) {
      if (item) this.delete_id = item.ki.map(data => data.id_competencies);
      this.confirmDelete = isOpen;
    },
    deleteCompetenciesById() {
      this.loading = true;
      let deleteId = [];

      if (this.delete_id) {
        deleteId = this.delete_id;
      } else {
        const newDeleteId = [];
        this.selected.map(r =>
          r.ki.map(k => newDeleteId.push(k.id_competencies))
        );
        deleteId = newDeleteId;
      }

      delete_competencies({ id_competencies: deleteId })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("basic_competencies.save_ki_delete_success"),
              color: "success"
            });
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.initData();
          this.loading = false;
          this.confirmDelete = false;
          this.selected = [];
          this.delete_id = null;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
